<template>
    <Datepicker 
        uid="start-time"
        inputClassName="form-control"
        :class="{'is-valid': isValidated && isValidValue, 'is-invalid': isValidated && !isValidValue}"
        v-model="value" 
        @update:model-value="(newValue) => $emit('update:modelValue', newValue)"
        aria-describedby="starttimeHelp" />
    <div v-if="!isValidated && textHelp" id="inputHelp" class="form-text">{{ textHelp }}</div>
    <div class="valid-feedback"> {{ validFeedbackText }} </div>
    <div class="invalid-feedback">{{ invalidFeedbackText }}</div>
</template>

<script>
import { computed, toRef } from 'vue'
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css';

export default {
    components: {
        Datepicker
    },
    props: {
        modelValue: {
            required: true
        },
        textHelp: {
            type: String,
            default: null
        },
        validFeedbackText: {
            type: String,
            default: ''
        },
        invalidFeedbackText: {
            type: String,
            default: ''
        },
    },
    
    setup(props) {

        const isValidValue = computed(() => {
            return !props.invalidFeedbackText || props.invalidFeedbackText === ''
        })

        const isValidated = computed(() => {
            return props.invalidFeedbackText !== '' || props.validFeedbackText !== ''

        })

        return {
            isValidValue,
            isValidated,
            value: toRef(props, 'modelValue')
        }
    }
}
</script>

<style>

</style>