<template>
<div class="modal fade" :id="idModal" :ref="idModal" tabindex="-1" aria-labelledby="modal-product" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ title }}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="mb-3">        
                        <label for="input-reference" class="form-label">Code</label>
                        <BasicInput 
                            v-model="code"
                            :invalidFeedbackText="valErrors.code"
                            textHelp="Set the work order unique identifier."/>
                    </div>
                    <div class="mb-3">
                        <label for="input-product" class="form-label">Product</label>
                        <SelectInput
                            :options="productsList" 
                            :reduceFun="item => item.reference"
                            :invalidFeedbackText="valErrors.product"
                            v-model="product" />
                    </div>
                    <div class="mb-3">
                        <label for="input-quantity" class="form-label">Quantity</label>
                        <BasicInput 
                            v-model="quantity"
                            inputType="number"
                            :invalidFeedbackText="valErrors.quantity"
                            textHelp="Work order number of items."/>
                        <!-- <input type="number" class="form-control" id="input-quantity" 
                                v-model="quantity"
                                aria-describedby="quantityHelp">
                        <div id="quantityHelp" class="form-text">Cantidad de artículos de la orden de trabajo.</div> -->
                    </div>
                    <div v-if="!isCreateForm()" class="mb-3">
                        <label for="input-quantity" class="form-label">Start date</label>
                        <DateTimeInput 
                            v-model="start_date"
                            :invalidFeedbackText="valErrors.start_date"
                            textHelp="Set when work order started."/>
                    </div>
                    <div v-if="!isCreateForm()" class="mb-3">
                        <label for="input-quantity" class="form-label">Finish date</label>
                        <DateTimeInput 
                            v-model="finish_date"
                            :invalidFeedbackText="valErrors.finish_date"
                            textHelp="Set when work order ended."/>
                    </div>
                    <div class="mb-3">
                        <label for="input-status" class="form-label">Status</label>
                        <select id="input-status" v-model="status" class="form-select" aria-label="Select status" required>
                            <option value="0">Stand by</option>
                            <option value="1">In progress</option>
                            <option value="2">Stopped</option>
                            <option value="3">Finished</option>
                        </select>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-danger" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" @click="submit">{{ submitButtonText }}</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { ref, onMounted } from 'vue';

import { getProducts } from '../helpers/endpoints';

import BasicInput from '../../shared/components/BasicInput';
import SelectInput from '../../shared/components/SelectInput';
import DateTimeInput from '../../shared/components/DateTimeInput';
import { useForm, propsForm } from '../../shared/composables/useForm';

export default {
    components: {
        BasicInput,
        SelectInput,
        DateTimeInput
    },
    props: {
        title: {
            required: true,
            type: String
        },
        idModal: {
            required: true,
            type: String
        },
        submitButtonText: {
            required: true,
            type: String
        },
        ...propsForm
    },
    
    emits: ['submit'],

    setup(props, { emit }) {
        const isCreateForm = () => !props.formData
        const fieldsDefinition = {
            'code': {
                default: '' 
            },
            'product': {
                default: undefined
            },
            'quantity': {
                default: undefined
            },
            'status': {
                default: 0
            },
            'start_date': {
                ignore: isCreateForm
            },
            'finish_date': {
                ignore: isCreateForm
            }
        }

        const productsList = ref([])

        const {
            formRefs,
            valErrors,
            cleanForm,
            cleanValidations,
            getFormData
        } = useForm(fieldsDefinition, props)

        onMounted(async () => {
            let products = await getProducts()
            products.forEach(element => {
                productsList.value.push({ label: element.reference, code: element.reference })
            })

            let $modal = document.getElementById(props.idModal)
            $modal.addEventListener('hidden.bs.modal', function () {
                cleanForm()
            })
        })

        return {
            ...formRefs,
            productsList,
            valErrors,
            isCreateForm,

            submit: ($event) => {
                cleanValidations()
                const formData = getFormData()
                console.log(formData);
                emit('submit', $event, formData)
            }
        }
    }
}
</script>
