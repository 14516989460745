<template>
<div class="grid-page-wrapper">
    <h1 class="display-4">Work order</h1>
    <hr>

    <ModalFormOrder 
        ref="formCreate"
        idModal="modal-order-create" 
        title="Create work order" 
        submitButtonText="Add order"
        :validations="formValidations"
        @submit="createItem"/>

    <ModalFormOrder 
        ref="formEdit"
        idModal="modal-order-edit" 
        :title="'Edit work order: ' + editData.code" 
        submitButtonText="Edit order"
        :formData="editData"
        :validations="formValidations"
        @submit="editItem"/>
    <div class="row mb-2">
        <div class="col-12">
            <button class="btn btn-success"
                    data-bs-toggle="modal" 
                    data-bs-target="#modal-order-create"
             ><i class="fa-solid fa-plus"></i> Add</button>
            <button class="btn btn-secondary dropdown-toggle mx-2"                     
                    id="btn-dropdown-export-table"
                    data-bs-toggle="dropdown">
                Export
            </button>
            <ul class="dropdown-menu" aria-labelledby="btn-dropdown-export-table">
                <li><button class="dropdown-item" @click="onBtExport()"><i class="fa-solid fa-file-csv"></i> CSV</button></li>
            </ul>
            <button v-if="showAll" @click="swapShowAll" class="btn btn-dark float-end">
                <i class="fa-solid fa-eye-slash"></i> Hide finished
            </button>
            <button v-else @click="swapShowAll" class="btn btn-dark float-end">
                <i class="fa-solid fa-eye"></i> Show finished
            </button>
        </div>
    </div>
    <div class="grid-wrapper">
        <ag-grid-vue
            class="backoffice-grid ag-theme-alpine"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            @grid-ready="onGridReady"
            :frameworkComponents="frameworkComponents"
            :paginationAutoPageSize="true"
            :pagination="true"
            :rowData="rowData">
        </ag-grid-vue>
    </div>
</div>


</template>


<script>
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"


import { ref } from 'vue';

import { AgGridVue } from "ag-grid-vue3"
import { Modal } from 'bootstrap'
import Swal from 'sweetalert2'

import TableButtonEdit from "../../shared/components/TableButtonEdit"
import TableButtonDelete from "../../shared/components/TableButtonDelete"
import {defaultButtonColumnDef, useGrid} from '../../shared/composables/useGrid'
import { dateComparator } from '../../shared/helpers/GridComparators'
import { dateRenderer } from '../../shared/helpers/GridRenderers'

import ModalFormOrder from '../components/ModalFormOrder'
import { getOrders, insertOrder, updateOrder, deleteOrder } from '../helpers/endpoints';
import CellGridOrderStatus from '../components/CellGridOrderStatus'


export default {
    components: {
        AgGridVue,
        ModalFormOrder
    },
    setup() {
        const editData = ref({})
        const formValidations = ref({})
        const showAll = ref(false)

        const renderTable = () => {
            getOrders({ get_all: showAll.value}).then((response) => {
                rowData.value = response;
                console.log(rowData.value);
            })
        }

        const { 
            gridApi,
            defaultColDef, 
            rowData,
            onGridReady,
            exportAsCSV
        } = useGrid(renderTable)

        const openEditModal = (formData) => {
            console.log(formData);
            editData.value = formData

            let modalEdit = new Modal(document.getElementById('modal-order-edit'))
            modalEdit.show()
        }

        const createItem = ($event, formData) => {
            console.log("Creating order");
            console.log($event);
            console.log(formData);
            insertOrder(formData).then((response) => {
                console.log(response);
                renderTable()

                let modalCreate = Modal.getInstance(document.getElementById('modal-order-create'))
                modalCreate.hide()

                Swal.fire(
                    'Good job!',
                    'Work order added with success',
                    'success'
                )
                
            }).catch((error) => {
                const { data } = error.response
                formValidations.value = data
            })
        }

        const editItem = ($event, formData) => {
            console.log("Editing order");
            console.log($event);
            console.log(formData);
            updateOrder(editData.value.id, formData).then((response) => {
                console.log(response);
                renderTable()

                let modalEdit = Modal.getInstance(document.getElementById('modal-order-edit'))
                modalEdit.hide()
                
                Swal.fire(
                    'Good job!',
                    'Work order edited with success',
                    'success'
                )
                
            }).catch((error) => {
                const { data } = error.response
                formValidations.value = data
            })

        }

        const removeItem = ({ id }) => {
            console.log(`Deleting order ${id}`);
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {

                    deleteOrder(id)
                        .then((response)=> {
                            console.log(response)
                            renderTable()
                            swalWithBootstrapButtons.fire(
                                'Deleted!',
                                'Work order has been deleted.',
                                'success'
                            )
                        }).catch((error) => {
                            console.log(error.response);
                            const { data } = error.response;
                            if (data.message) {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: data.message
                                })
                            }
                        })
                    
                } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    swalWithBootstrapButtons.fire(
                        'Cancelled',
                        'Your work order is safe :)',
                        'error'
                    )
                }
            })
        }

        const getColumnDefs = () => [
                { headerName: "Code", field: "code", filter: 'agTextColumnFilter' },
                { headerName: "Product", field: "product", filter: 'agTextColumnFilter' },
                { headerName: "Quantity", field: "quantity", filter: 'agNumberColumnFilter' },
                {
                    headerName: "Status", 
                    field: "status",
                    cellRenderer: 'statusRenderer'
                },
                { 
                    headerName: "Start date", 
                    field: "start_date", 
                    filter: 'agDateColumnFilter',
                    cellRenderer: dateRenderer,
                    // add extra parameters for the date filter
                    filterParams: {
                        // provide comparator function
                        comparator: dateComparator
                    } 
                },
                { 
                    headerName: "Finish date", 
                    field: "finish_date", 
                    hide: !showAll.value,
                    filter: 'agDateColumnFilter',
                    cellRenderer: dateRenderer,
                    // add extra parameters for the date filter
                    filterParams: {
                        // provide comparator function
                        comparator: dateComparator
                    } 
                },
                {
                    field: 'id',
                    cellRenderer: 'btnEditRenderer',
                    cellRendererParams: {
                        action: openEditModal
                    },
                    ...defaultButtonColumnDef
                },
                {
                    field: 'id',
                    cellRenderer: 'btnDeleteRenderer',
                    cellRendererParams: {
                        action: removeItem
                    },
                    ...defaultButtonColumnDef
                }
            ]
        return {
            columnDefs: getColumnDefs(),
            defaultColDef,
            frameworkComponents: {
                btnEditRenderer: TableButtonEdit,
                btnDeleteRenderer: TableButtonDelete,
                statusRenderer: CellGridOrderStatus
            },
            rowData,
            editData,
            formValidations,
            onBtnExport: () => exportAsCSV([
                    'code',
                    'product',
                    'quantity',
                    'status',
                    'start_date',
                ]),
            onGridReady,

            showAll,
            swapShowAll: () => {
                showAll.value = !showAll.value;
                renderTable();
                gridApi.value.setColumnDefs(getColumnDefs());
            },

            createItem,
            editItem
        }
    }
}
</script>
<style lang="scss" scoped>
</style>